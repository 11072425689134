import { getCapacityListWithFilter } from "@/services/api/capacity";
import { getFlags } from "@/services/api/favourite";

export default {
  storeForm(context, payLoad) {
    context.commit("STOREFORM", payLoad);
  },
  resetForm(context) {
    context.commit("RESETFORM");
  },
  setCapacityList(context, payload) {
    context.commit("SET_CAPACITYLIST", payload);
  },
  async fetchCapacityList(context, filter) {
    let capacityList = await getCapacityListWithFilter(filter);
    if (capacityList && capacityList.data) {
      context.commit("SET_CAPACITYLIST", capacityList.data);
    } else {
      context.commit("SET_CAPACITYLIST", []);
    }
  },
  setFavouriteList(context, payload) {
    context.commit("SET_FAVOURITELIST", payload);
  },
  async fetchFavouriteList(context) {
    let favouriteList = await getFlags();
    if (favouriteList && favouriteList.data) {
      context.commit("SET_FAVOURITELIST", favouriteList.data);
    } else {
      context.commit("SET_FAVOURITELIST", []);
    }
  },
};

export default {
  SET_MESSAGES(state, messages) {
    state.messageHistory = [];
    messages.forEach((element) => {
      state.messageHistory.push(element.entry);
    });
  },
  ADD_MESSAGE(state, message) {
    state.messageHistory.push(message);
  },
};

import Vue from "vue";

export const getConversation = (id) => {
  return Vue.axios.get("/api/conversation/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getConversationsByCapacity = (id) => {
  return Vue.axios.get("/api/conversation/capacity/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getConversationsByCapacityAndCompany = (id, companyId) => {
  return Vue.axios.get(
    "/api/conversation/capacity/" + id + "/company/" + companyId,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const saveConversation = (data) => {
  return Vue.axios.post("/api/conversation", JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
};

export const saveMessageToConversation = (data) => {
  return Vue.axios.post("/api/conversation/message", JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteConversation = (id) => {
  return Vue.axios.delete("/api/conversation/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

// import store from "./../../store";
import Vue from "vue";
// import axios from "axios";

// When the request succeeds
/* const success = user => {
  store.dispatch("currentUser", user);
};

export default () => {
  const succeeds = true;

  if (succeeds) {
    success({
      first_name: "John",
      last_name: "Doe",
      id: "012345"
    });
  } else {
    failed();
  }
}; */
export const validateUser = (email) => {
  return Vue.axios.post("api/user/validate", JSON.stringify({ email: email }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const testProtected = () => {
  console.info("GET /api/app/protected");
  return Vue.axios.get("api/app/protected", {
    headers: { "Content-Type": "application/json" },
  });
};

export const getUserProfile = () => {
  return Vue.axios.get("api/app/currentuser", {
    headers: { "Content-Type": "application/json" },
  });
};

export const setActiveOrganization = (id) => {
  return Vue.http.post("user/current/organization", JSON.stringify(id), {
    headers: { "Content-Type": "application/json" },
  });
};

export const changeUserSettings = (account) => {
  return Vue.http.post("user/current", JSON.stringify(account), {
    headers: { "Content-Type": "application/json" },
  });
};

export const getUserList = () => {
  return Vue.axios.get("api/app/users", {
    headers: { "Content-Type": "application/json" },
  });
};

export const postUser = (user) => {
  return Vue.http.post("api/app/user", JSON.stringify(user), {
    headers: { "Content-Type": "application/json" },
  });
};
export default {
  setAccount(context, account) {
    context.commit("setAccount", account);
  },
  logout(context, account) {
    context.commit("logout", account);
  },
  setExclusiveCompanyId(context, id) {
    context.commit("setExclusiveCompanyId", id);
  }
};

<template>
  <div class="wrapper">
    <keep-alive include="navbar">
      <navbar-work
        :hasSimpleNav="!bignav"
        :navSubTitle="navSubTitle"
        :isFrontPage="false"
      ></navbar-work>
    </keep-alive>

    <sidebar-menu
      :menu="menu"
      :width="width"
      :collapsed="collapsed"
      :showChild="showChild"
      :showOneChild="showOneChild"
      @toggle-collapse="onToggleCollapse"
    >
      <span slot="dropdown-icon"><i class="las la-angle-right"></i></span>
      <span slot="toggle-icon"><i class="las la-angle-double-right"></i></span>
    </sidebar-menu>

    <div
      class="main"
      :style="
        menuCollapse
          ? 'margin-left: 50px'
          : 'margin-left: 200px; width: -webkit-fill-available;'
      "
    >
      <slot></slot>
    </div>
    <keep-alive include="sidebar-right">
      <sidebar-right></sidebar-right>
    </keep-alive>
    <footer-area
      v-if="hideFooter !== true"
      :expanded="expandfooter"
    ></footer-area>
    <div v-if="showOverlay" class="overlay"></div>
  </div>
</template>

<script>
// import Vue from "vue";
import Navbar from "@/components/navbar/navbarwork.vue";
import Footer from "@/components/footer/footerArea.vue";
//import Sidebar from "@/components/sidebarright/sidebarright.vue";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "work-layout",
  data() {
    return {
      showOverlay: false,
      menuCollapse: this.collapsed,
      menu: [
        {
          header: true,
          title: "",
        },
        {
          href: "/work",
          title: "DeliverIt Work",
          icon: {
            element: "span",
            class: "la la-home",
            // attributes: {}
            //text: "Forsiden"
          },
        },
        {
          href: "/work/findCases",
          title: "Finn oppdrag",
          icon: {
            element: "span",
            class: "la la-search",
            // attributes: {}
            //text: "Forsiden"
          },
        },
        {
          href: "/work/activeCases",
          title: "Aktive saker",
          icon: {
            element: "span",
            class: "las la-file-invoice",
            // attributes: {}
            //text: "Forsiden"
          },
        },
        {
          href: "",
          title: "Registrere...",
          icon: {
            element: "span",
            class: "la la-pen",
          },
          child: [
            {
              href: "/work/capacity",
              title: "Ledig kapasitet",
              icon: {
                element: "span",
                class: "las la-truck-loading",
              },
              hiddenOnCollapse: false,
            },
            {
              href: "/work/needCapacity",
              title: "Behov for kapasitet",
              icon: {
                element: "span",
                class: "las la-box-open",
              },
              hiddenOnCollapse: false,
            },
          ],
        },
        {
          href: "",
          title: "Admin",
          icon: {
            element: "span",
            class: "la la-cogs",
          },
          child: [
            {
              href: "/work/admin/adminusers",
              title: "Brukere",
              icon: {
                element: "span",
                class: "la la-users",
              },
              hiddenOnCollapse: false,
            },
            {
              href: "/work/admin/settings",
              title: "Innstillinger",
              icon: {
                element: "span",
                class: "la la-list",
              },
              hiddenOnCollapse: false,
            },
            {
              href: "/work/admin/vehicles",
              title: "Kjøretøy",
              icon: {
                element: "span",
                class: "la la-truck",
              },
              hiddenOnCollapse: false,
            },
            {
              href: "/work/admin/routes",
              title: "Ruter",
              icon: {
                element: "span",
                class: "la la-map",
              },
              hiddenOnCollapse: false,
            },
            {
              href: "/work/admin/notifications",
              title: "Varsler",
              icon: {
                element: "span",
                class: "la la-bell",
              },
              hiddenOnCollapse: false,
            },
            {
              href: "/work/admin/subscription",
              title: "Abonnement",
              icon: {
                element: "span",
                class: "las la-handshake",
              },
              hiddenOnCollapse: false,
            },
          ],
        },
      ],
    };
  },
  props: {
    bignav: {
      type: Boolean,
      default: false,
    },
    expandfooter: {
      type: Boolean,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    navSubTitle: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "200px",
    },
    widthCollapsed: {
      type: String,
      default: "150px",
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
    showChild: {
      type: Boolean,
      default: false,
    },
    showOneChild: {
      type: Boolean,
      default: false,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "footer-area": Footer,
    "navbar-work": Navbar,
    "sidebar-right": () =>
      import(
        /* webpackChunkName: "sidebar" */ "@/components/sidebarright/sidebarright.vue"
      ),
    SidebarMenu,
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.menuCollapse = collapsed;
    },
    //onItemClick(event, item, node) {}
  },
};
</script>

<style scoped></style>

import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "../plugins/auth";
// import i18n from "../i18n";
//import Home from "@/views/home/home.vue";
//import AssignmentForm from "@/views/assignmentform/assignmentform.vue";
//import AssignmentList from "@/views/assignmentlist/assignmentlist.vue";
//import OfferList from "@/views/offerlist/offerlist.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: "/", //process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/work/work.vue"),
      meta: {
        layout: "standard",
        navSizeBig: true,
        expandfooterBtn: true,
      },
    },
    {
      path: "/auth/callback/:provider",
      name: "AuthCallback",
      component: {
        template: '<div class="auth-component"></div>',
      },
    },
    {
      path: "/work",
      name: "Work",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/work/work.vue"),
      meta: {
        layout: "standard",
        navSizeBig: true,
        navSubTitle: "@ Work",
        expandfooterBtn: true,
      },
    },
    {
      name: "capacity",
      path: "/work/capacity",
      beforeEnter: authGuard,
      component: () =>
        import(
          /* webpackChunkName: "capacity" */ "@/views/work/capacity/capacity.vue"
        ),
      meta: {
        layout: "work",
        navSizeBig: false,
        navSubTitle: "@ Work",
        expandfooterBtn: true,
      },
    },
    {
      name: "NeedCapacity",
      path: "/work/needCapacity",
      beforeEnter: authGuard,
      component: () =>
        import(
          /* webpackChunkName: "needcapacity" */ "@/views/work/needcapacity/needcapacity.vue"
        ),
      meta: {
        layout: "work",
        navSizeBig: false,
        navSubTitle: "@ Work",
        expandfooterBtn: true,
      },
    },
    {
      name: "ActiveCases",
      path: "/work/activeCases",
      beforeEnter: authGuard,
      component: () =>
        import(
          /* webpackChunkName: "activecases" */ "@/views/work/activecases/activecases.vue"
        ),
      props: true,
      meta: {
        layout: "work",
        navSizeBig: false,
        navSubTitle: "@ Work",
        expandfooterBtn: true,
      },
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /work/activeCases/:id/details is matched
          name: "CaseDetails/:caseId?",
          path: "details",
          props: true,
          component: {
            dialog: () =>
              import(
                /* webpackChunkName: "casedetails" */ "@/components/work/casedetails/casedetails.vue"
              ),
            default: () => import("@/views/work/activecases/activecases.vue"),
          },
          beforeEnter: authGuard,
        },
      ],
    },
    {
      name: "FindCases",
      path: "/work/findCases/:caseId?",
      component: () =>
        import(
          /* webpackChunkName: "capacitylist" */ "@/views/work/capacitylist/capacitylist.vue"
        ),
      beforeEnter: authGuard,
      props: true,
      meta: {
        layout: "work",
        navSizeBig: false,
        navSubTitle: "@ Work",
        expandfooterBtn: false,
        hideFooter: true,
      },
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /work/findCases/:caseId/details is matched
          name: "FindCaseDetails",
          path: "details",
          component: {
            dialog: () =>
              import(
                /* webpackChunkName: "casedetails" */ "@/components/work/casedetails/casedetails.vue"
              ),
            default: () => import("@/views/work/activecases/activecases.vue"),
          },
          beforeEnter: authGuard,
        },
      ],
    },
    {
      name: "AdminPage",
      path: "/work/admin",
      component: () =>
        import(
          /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminpage/adminpage.vue"
        ),
      beforeEnter: authGuard,
      meta: {
        layout: "work",
        navSizeBig: false,
        navSubTitle: "@ Work - Admin",
        expandfooterBtn: true,
      },
      children: [
        {
          // UserProfile will be rendered inside User's <router-view>
          // when /work/activeCases/:id/details is matched
          name: "AdminUsers",
          path: "adminusers",
          component: () =>
            import(
              /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminusers/adminusers.vue"
            ),
          meta: {
            layout: "work",
            navSizeBig: false,
            navSubTitle: "@ Work - Admin",
            expandfooterBtn: true,
          },
        },
        {
          name: "AdminSettings",
          path: "settings",
          component: () =>
            import(
              /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminsettings/adminsettings.vue"
            ),
          meta: {
            layout: "work",
            navSizeBig: false,
            navSubTitle: "@ Work - Admin",
            expandfooterBtn: true,
          },
        },
        {
          name: "AdminVehicles",
          path: "vehicles",
          component: () =>
            import(
              /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminvehicles/adminvehicles.vue"
            ),
          meta: {
            layout: "work",
            navSizeBig: false,
            navSubTitle: "@ Work - Admin",
            expandfooterBtn: true,
          },
        },
        {
          name: "AdminRoutes",
          path: "routes",
          component: () =>
            import(
              /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminroutes/adminroutes.vue"
            ),
          meta: {
            layout: "work",
            navSizeBig: false,
            navSubTitle: "@ Work - Admin",
            expandfooterBtn: true,
          },
        },
        {
          name: "AdminNotifications",
          path: "notifications",
          component: () =>
            import(
              /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminnotifications/adminnotifications.vue"
            ),
          meta: {
            layout: "work",
            navSizeBig: false,
            navSubTitle: "@ Work - Admin",
            expandfooterBtn: true,
          },
        },
        {
          name: "AdminSubscription",
          path: "subscription",
          component: () =>
            import(
              /* webpackChunkName: "workadmin" */ "@/views/work/admin/adminsubscription/adminsubscription.vue"
            ),
          meta: {
            layout: "work",
            navSizeBig: false,
            navSubTitle: "@ Work - Admin",
            expandfooterBtn: true,
          },
        },
      ],
    },
    {
      path: "*",
      name: "404*",
      component: () =>
        import(/* webpackChunkName: "404" */ "@/views/404/404.vue"), //.default // load sync home
    },
  ],
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

/* .beforeEach((to, from, next) => {
  const lang = to.params.lang;
  if (lang) i18n.loadLanguageAsync(lang).then(() => next());
}); */

/* eslint-disable */
export default {
  isCompany(state, getters) {
    return (
      state.activeOrganization &&
      typeof state.activeOrganization.id !== "undefined"
    );
  },
  isTransporter(state, getters) {
    return state.canTransport;
  },
  companyName(state, getters) {
    if (state.companyName) {
      return state.companyName;
    } else {
      return "";
    }
  },
  companyId(state, getters) {
    if (state.companyId) {
      return state.companyId;
    } else {
      return "";
    }
  },
  companyLogo(state, getters) {
    if (state.companyLogo) {
      return state.companyLogo;
    } else {
      return "";
    }
  },
  exclusiveCompanyId(state, getters) {
    if(state.exclusiveCompanyId) {
      return state.exclusiveCompanyId;
    } else {
      return null;
    }
  },
  exclusiveCompanySince(state, getters) {
    if(state.exclusiveCompanySince) {
      return state.exclusiveCompanySince;
    } else {
      return null;
    }
  },
};

//import Vue from "vue";
import { LOGOUT, CHECK_AUTHENTICATION, LOGIN } from "./../../mutation-types";

export default {
  [CHECK_AUTHENTICATION](state) {
    state.authenticated = true;
    //state.authenticated = !!localStorage.getItem('id_token');
    if (state.authenticated) {
      //Vue.http.headers.common.Authorization = `Bearer ${localStorage.getItem('id_token')}`;
    }
  },

  [LOGIN](state /*, _token */) {
    state.auth0.authorize();
    //state.authenticated = true;

    //localStorage.setItem("id_token", token);
    //localStorage.setItem("apollo_token", token);
    // Vue.http.headers.common.Authorization = `Bearer ${token}`;
  },

  [LOGOUT](state) {
    state.authenticated = false;
    localStorage.removeItem("id_token");
    localStorage.removeItem("apollo_token");
    localStorage.removeItem("dittoken");
    // Vue.http.headers.common.Authorization = "";
    state.auth0 = null;
    state.access_token = "";
  },
  SETAUTH0OBJECT(state, payload) {
    state.auth0 = payload;
    state.access_token = payload.access_token;
  },
};

import {
  getConversation,
  saveMessageToConversation,
} from "@/services/api/conversation";

export default {
  async fetchMessagesHistory(context, conversationId) {
    let conversation = await getConversation(conversationId);
    context.commit("SET_MESSAGES", conversation.data.messages);
  },
  async addMessage(context, payload) {
    let conversation = await saveMessageToConversation(
      payload.data,
      payload.id
    );
    context.commit("ADD_MESSAGE", conversation.data.messages);
  },
};

/* ============
 * Vuex Store
 * ============
 *
 * The store of the application
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
//import * as actions from "./actions";
//import * as getters from "./getters";

// Modules
import account from "./modules/account";
import auth from "./modules/auth";
import user from "./modules/user";
import capacity from "./modules/capacity";
import needCapacity from "./modules/needCapacity";
import settings from "./modules/settings";
import messages from "./modules/messages";
import filter from "./modules/filter";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  //modules: ["user","assignment"]
});

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

var vuexPlugins = [];
if (window.localStorage) {
  window.localStorage.setItem("dit", "ok");
  if (window.localStorage.getItem("dit") === "ok") {
    vuexPlugins.push(vuexLocal.plugin);
  }
}
if (debug) vuexPlugins.push(createLogger());

export default new Vuex.Store({
  namespaced: true,
  modules: {
    account,
    auth: auth,
    user,
    capacity,
    needCapacity,
    settings,
    messages,
    filter,
  },

  /**
   * If strict mode should be enabled
   */
  strict: debug,

  /**
   * Plugins used in the store
   */
  plugins: vuexPlugins,
});

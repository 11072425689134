/* eslint-disable */
export default {
  phone(state, getters) {
    if (state.phone) {
      return state.phone;
    } else {
      return [];
    }
  },
  position(state, getters) {
    let position = {};
    if (state.lat) {
      position.lat = state.lat;
      position.lng = state.lng;
    }
    return position;
  }
};

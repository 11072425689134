import Vue from "vue";

export const setFlagOnCapacity = (capacity, isFlagged) => {
  return Vue.axios.get(
    "/api/favourite/" + capacity + "/" + (isFlagged ? "1" : "0"),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const getFlags = () => {
  return Vue.axios.get("/api/favourite", {
    headers: { "Content-Type": "application/json" },
  });
};

import Vue from "vue";
import VueI18n from "vue-i18n";
// import axios from "axios";

//import BeeLocale from "beegridtable/src/locale"; //default English
//import BeelocaleDK from "beegridtable/src/locale/lang/da-DK";
import BeelocaleNO from "./locales/beeGrid_nb-NO";

Vue.use(VueI18n);
Vue.locale = () => {}; //necessary for BeeGrid

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  messages["no"].i = {};
  messages["no"].i = BeelocaleNO.i;
  return messages; //Object.assign(messages.no, BeelocaleDK);
}
console.info("BEE i18n: ", BeelocaleNO);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  silentFallbackWarn: true,
  messages: loadLocaleMessages()
});
export default i18n;

// ------------ Lazy loading
/* const loadedLanguages = [process.env.VUE_APP_I18N_LOCALE || "en"]; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      // return import(/* webpackChunkName: "lang-[request]" * / `@/lang/${lang}`).then(
        msgs => {
          i18n.setLocaleMessage(lang, msgs.default);
          loadedLanguages.push(lang);
          return setI18nLanguage(lang);
        }
      );
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
} */
// ---------------------------

export default {
  currentFilter(state /*, getters */) {
    return state.currentFilter;
  },
  currentFilterOwnlist(state /*, getters */) {
    return state.currentFilterOwnlist;
  },
  filterList(state /*, getters */) {
    return state.filterList;
  },
  filterForm(state /*, getters */) {
    return state.filterForm;
  },
};

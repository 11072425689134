/* ============
 * Account Module
 * ============
 */
//import { getField, updateField } from "vuex-map-fields";
import { createHelpers } from "vuex-map-fields";
const { getStructureField, updateStructureField } = createHelpers({
  getterType: "getStructureField",
  mutationType: "updateStructureField",
});
/* const { getAssignmentField, updateAssignmentField } = createHelpers({
  getterType: "getAssignmentField",
  mutationType: "updateAssignmentField"
});  */

import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
//import { mapGetters } from "vuex";

export default {
  namespaced: true,
  state,
  getters: {
    getStructureField, //getField //,getters
    ...getters,
  },
  mutations: {
    updateStructureField, //updateField //, mutations
    ...mutations,
  },
  actions,
};

<template>
  <div id="app">
    <component :is="layout" v-bind="boundProps">
      <router-view></router-view>
      <router-view name="dialog"></router-view>
      <portal-target name="smallmodal"></portal-target>
      <portal-target name="bigmodal">
        <!--
        This component can be located anywhere in your App.
        The slot content of the above portal component will be rendered here.
        -->
      </portal-target>
    </component>
  </div>
</template>

<script>
const default_layout = "standard";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    boundProps() {
      return {
        bignav: this.$route.meta.navSizeBig || false,
        expandfooter: this.$route.meta.expandfooterBtn || false,
        hideFooter: this.$route.meta.hideFooter || false,
        navSubTitle: this.$route.meta.navSubTitle || "",
      };
    },
  },
  created() {
    // nothing defined here (when this.$route.path is other than "/")
    console.log(this.$route, this.$route.meta.layout);
  },
  updated() {
    // something defined here whatever the this.$route.path
    console.log(this.$route, this.$route.meta.layout);
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

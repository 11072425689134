export default {
  id: null,
  firstName: null,
  lastName: null,
  timeZone: null,
  currentTimeZoneOffset: null,
  language: null,
  isDaylightSavingTime: true,
  companyName: null,
};

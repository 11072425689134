/* eslint-disable */
<template>
  <footer class="footer" :class="{ 'footer--hidden': expanded === false }" id="footer">
    <div class="shell">
      <div class="footer__aside">
        <a href="#" class="footer__logo">
          <i class="ico-logo"></i>
          <span>DeliverIt</span>
        </a>


        <ul class="list-contacts">
          <li>
            <span>Transportvein 99,</span> 4905 Leveransstad,
            <span>Norway</span>
          </li>
          <li>
            <span>
              Telefon
              <small>(norway)</small>:
            </span>
            <a href="tel:+479999878389">+47 9999 878 389</a>
          </li>
          <li>
            <span>Epost:</span>
            <a href="mailto:info@deliverit.app">info@deliverit.app</a>
          </li>
        </ul>
        <!-- /.list-contacts -->
        
        <div class="socials">
          <ul>
            <li>
              <a href="#">
                <i class="ico-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="ico-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="ico-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- /.socials -->
      </div>
      <!-- /.footer__aside -->
      <div class="footer__aside footer__aside--right">
        <div class="cols">
          <div class="col col--size1">
            <h6>GENERAL INFO</h6>

            <ul>
              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="#">Client Gateway</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#">Ranking</a>
              </li>
              <li>
                <a href="#">Case Studies</a>
              </li>
              
              
            </ul>
          </div>
          <!-- /.col col-size1 -->
          <div class="col col--size2">
            <h6>MORE LINKS</h6>
            <ul>
              <li>
                <a href="#">F.A.Q.</a>
              </li>
              <li>
                <a href="#">Contact us</a>
              </li>
              
            </ul>
          </div>
          <!-- /.col col-size2 -->
        </div>
        <!-- /.cols -->
        
        <!-- /.list-posts -->
      </div>
      <!-- /.footer__aside -->
    </div>
    <!-- /.shell -->
    <div class="shell">
      <div > <!-- class="footer__inner" -->
        <p class="copyright">(C) 2020 - All Rights with DeliverIt Solutions</p>
        <!-- /.copyright -->
        <p class="credits">
          Deliver<span>It</span>.app
        </p>
      </div>
      <!-- /.footer__inner -->
    </div>
    <!-- /.shell -->
    <cookie-law theme="dark-lime"></cookie-law>
  </footer>
  <!-- /.footer -->
</template>

<script>
import CookieLaw from "vue-cookie-law";

export default {
  name: "footer-area",
  data() {
    return {};
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  components: { CookieLaw },
  methods: {}
};
</script>

const clonedeep = require("lodash.clonedeep");

export default {
  STOREFORM(state, payload) {
    //console.info("committing Form ?", state);
    //state.assignmentForm = payload;
    state.capacityForm = clonedeep(payload);
    //console.info("committing Form ?", state);
  },
  RESETFORM(state) {
    state.capacityForm = {};
  },
  SET_CAPACITYLIST(state, payload) {
    state.capacityList = clonedeep(payload);
  },
  SET_FAVOURITELIST(state, payload) {
    state.favouriteList = clonedeep(payload);
  },
};

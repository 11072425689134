import { SET_LANGUAGE } from "./../../mutation-types";

export default {
  setPosition(state, position) {
    //state.id = account.id;
    console.info("IN STORE:", position);
    state.lat = position.lat;
    state.lng = position.lng;
  },
  [SET_LANGUAGE](state, account) {
    state.language = account.language;
  }
};

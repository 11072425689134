<template>
  <header
    class="header"
    :class="{
      fixed: position > 0 || hasSimpleNav,
      'header--simple': hasSimpleNav,
      'header--simple_work': isFrontPage,
    }"
  >
    <div class="shell shell-fluid">
      <b-navbar type="dark" toggleable="md" class="header__inner">
        <a href="/" class="logo">
          <i class="ico-logo"></i>
          <span>DeliverIt</span>

          <small class="d-none d-lg-inline">{{
            navSubTitle !== "" ? navSubTitle : "@ WORK" + navSubTitle
          }}</small> </a
        >{{ isAuthenticated }}
        <b-btn
          v-if="$mq === 'xs' || $mq === 'sm'"
          class="float-right"
          oldclick.prevent="showSidebar = !showSidebar"
          oldtarget="sidebar-login"
        >
          <i
            v-if="isAuthenticated"
            class="las la-user"
            @click="openUserProfile()"
          ></i>
          <i v-else class="las la-bars" @click="login"></i>
        </b-btn>
        <b-btn
          v-else-if="isAuthenticated"
          size="lg"
          class="m-2"
          stylexx="top:-25px;position:relative;"
          @click="openUserProfile()"
        >
          <i class="las la-user"></i>&nbsp;<strong>{{
            $store.state.auth.auth0.given_name ? $store.state.auth.auth0.given_name : $store.state.auth.auth0.name
          }}</strong>
          {{ $store.state.auth.auth0.family_name }}
          <br />
          <span v-if="companyName && companyName !== ''">{{
            companyName
          }}</span>
        </b-btn>
        <b-btn v-else size="lg" class="m-2" @click="login">
          <div slot="button-content">
            <div id="userChoice">
              <strong>Logg inn</strong>
            </div>
          </div>
        </b-btn>
      </b-navbar>
      <!-- /.form-check-delivery -->
    </div>
    <!-- /.shell -->
    <b-sidebar
      id="sidebar-login"
      :title="isAuthenticated ? 'Din profil' : 'Velg innlogging'"
      backdrop-variant="light"
      backdrop
      shadow="true"
      right
      :visible="showSidebar"
      @hidden="showSidebar = false"
    >
      <div class="px-3 py-2">
        <div v-if="isAuthenticated">
          <img
            :src="$store.getters['account/companyLogo']"
            width="50"
            class="nav-companyLogo"
          />
          <label class="form__label">{{ $t("sidebar.companyname") }}</label>
          <div v-if="companyName && companyName !== ''">{{ companyName }}</div>
          <label class="form__label">{{ $t('sidebar.username') }}</label>
          <div>{{ $store.getters["auth/userName"] }}</div>
          <div
            id="logoutBtn"
            class="float-right h4 align-bottom"
            style="position: absolute; bottom: 0; right: 10px"
          >
            <div class="btn" @click="logout">Logg ut</div>
          </div>
        </div>
        <div v-else class="socialLoginArea">
          <b-btn size="lg" class="m-2" @click="login">
            <div slot="button-content">
              <div id="userChoiceInSidebar">
                <strong>Logg inn</strong>
              </div>
            </div>
          </b-btn>
        </div>
      </div>
    </b-sidebar>
    <!-- /.header__inner -->
  </header>
</template>

<script>
// import Vue from "vue";
//import { EventBus } from "@/utils/event-bus.js";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import store from "@/store/index.js";
import { BNavbar, BButton, BSidebar } from "bootstrap-vue";
//import CountryFlag from "vue-country-flag";

import { getUserProfile, testProtected } from "@/services/api/workUsers";

//import VueFire from 'vuefire'  // import VueFirestore from 'vue-firestore'
//import Firebase from 'firebase'
//require('firebase/firestore')

export default {
  name: "navbar-work",
  data() {
    return {
      position: 0,
      userData: [],
      userColl: [],
      activeUser: {},
      response: null,
      providerList: [{ id: 1, name: "google", displayName: "Google" }],
      showSidebar: false,
    };
  },
  props: {
    hasSimpleNav: {
      type: Boolean,
      default: false,
    },
    navSubTitle: {
      type: String,
      default: "",
    },
    isFrontPage: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isAuthenticated: function (newValue) {
      this.showSidebar = newValue === true ? false : true;
      //this.showSidebar = newValue === true ? false : true;
    },
  },
  computed: {
    bSimpleNav() {
      return this.hasSimpleNav;
    },
    isAuthenticated() {
      if (this.$store && this.$store.getters["auth/userName"]) {
        return typeof this.$store.getters["auth/userName"] === "string";
      } else {
        return false;
      }
    },
    hasActiveOrders() {
      if (!this.$store.state.assignment.assignmentList) return false;
      let active = this.$store.state.assignment.assignmentList.find(
        (assignment) => {
          return assignment.status === "sent" || assignment.status === "draft";
        }
      );
      return active && active.length > 0;
    },
    companyName() {
      if (this.$store.state.user && this.$store.state.user.companyName) {
        return this.$store.state.user.companyName;
      } else {
        return "";
      }
    },
  },
  components: {
    "b-navbar": BNavbar,
    "b-btn": BButton,
    "b-sidebar": BSidebar,
  },
  /* firestore: {
    userF: this.userColl ? this.userColl.limitToLast(6) : [] // this.user
  }, */
  methods: {
    testProtected,
    getUserProfile,
    // eslint-disable-next-line
    onScroll: function(e, position) {
      //var el = document.querySelector("body");
      //console.info("scroll:", window.scrollY);
      if (e.target) {
        if (e.target) {
          //console.info("scroll 1:", e.target);
          // this.$set(this, "position", window.scrollY);
          this.position = window.scrollY;
        } else {
          console.info("scroll IE ?", document.documentElement.scrollTop);
          this.position = document.documentElement.scrollTop;
        }
      }
      //console.info("Scroll PosTop: ", this.position);
      //this.position = position.scrollTop
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    },
    openUserProfile() {
      //EventBus.$emit("openUserProfile");
      this.showSidebar = !this.showSidebar;
    } /* ,
		customLabel ({ firstName, lastName, company }) {
			return `${firstName} ${lastName}<br>${company}`
		}*/,
    changeProfile(profile) {
      console.info("HER as is:", this.$store.state.account);
      store.dispatch("account/setAccount", profile);
    },

    startTour(tourName) {
      console.info("Tour: ", tourName);
      this.$tours[tourName].start();
    },

    auth: function (provider) {
      if (this.$auth.isAuthenticated()) {
        console.info("LOGOUT");
        this.$auth.logout();
      }

      this.response = null;
      console.info("OPEN ", provider);

      var this_ = this;
      this.$auth
        .authenticate(provider)
        .then(function (authResponse) {
          if (provider === "github") {
            this_.$http
              .get("https://api.github.com/user")
              .then(function (response) {
                this_.response = response;
              });
          } else if (provider === "facebook") {
            this_.$http
              .get("https://graph.facebook.com/v2.5/me", {
                params: { access_token: this_.$auth.getToken() },
              })
              .then(function (response) {
                this_.response = response;
              });
          } else if (provider === "google") {
            if (
              authResponse &&
              authResponse.data &&
              authResponse.data.profile
            ) {
              console.info("FROM  API:", authResponse);
              var profile = {};
              profile.id = authResponse.data.id || "pending";
              profile.firstName = authResponse.data.profile.given_name;
              profile.lastName = authResponse.data.profile.family_name;
              profile.email = authResponse.data.profile.email;
              profile.fullName = authResponse.data.profile.name;
              profile.activeOrganization =
                authResponse.data.profile.company || "";
              profile.locale = authResponse.data.profile.locale;
              profile.picture = authResponse.data.profile.picture;
              //store.dispatch("account/setAccount", profile);
              store.dispatch("auth/login", authResponse.data.token);

              localStorage.setItem("id_token", authResponse.data.token);
              localStorage.setItem("apollo_token", authResponse.data.token);

              this_.$http.defaults.headers.common.Authorization = `Bearer ${authResponse.data.token}`;

              var cook = document.cookie.split(";").filter((item) => {
                return item.indexOf("deliverit-jwt=") >= 0;
              });
              if (cook.length) {
                //foo exists
                console.info("COOKIE: ", cook);
              }
              localStorage.setItem("dittoken", authResponse.data.token);
              this_.showSidebar = false;
            } else {
              // OLD:  https://www.googleapis.com/plus/v1/people/me/openIdConnect
              this_.$http
                .get("https://www.googleapis.com/oauth2/v2/userinfo")
                .then(function (response) {
                  this_.response = response;
                  console.info("FROM  GOOGLE:", response);
                  var profile = {};
                  profile.id = "pending";
                  profile.firstName = response.data.given_name;
                  profile.lastName = response.data.family_name;
                  profile.email = response.data.email;
                  profile.fullName = response.data.name;
                  profile.activeOrganization = "";
                  //profile.locale = response.data.locale;
                  //store.dispatch("account/setAccount", profile);
                  store.dispatch("auth/login", authResponse.data.token);

                  localStorage.setItem("id_token", authResponse.data.token);
                  localStorage.setItem("apollo_token", authResponse.data.token);

                  this_.$http.defaults.headers.common.Authorization = `Bearer ${authResponse.data.token}`;

                  var cook = document.cookie.split(";").filter((item) => {
                    return item.indexOf("deliverit-jwt=") >= 0;
                  });
                  if (cook.length) {
                    //foo exists
                    console.info("COOKIE: ", cook);
                  }
                  localStorage.setItem("dittoken", authResponse.data.token);
                  this_.showSidebar = false;
                });
            }
          } else if (provider === "twitter") {
            this_.response = authResponse.body.profile;
          } else if (provider === "instagram") {
            this_.response = authResponse;
          } else if (provider === "bitbucket") {
            this_.$http
              .get("https://api.bitbucket.org/2.0/user")
              .then(function (response) {
                this_.response = response;
              });
          } else if (provider === "linkedin") {
            this_.response = authResponse;
          } else if (provider === "live") {
            this_.response = authResponse;
          }
        })
        .catch(function (err) {
          this_.response = err;
          console.info("ERROR FROM  GOOGLE:", err);
          //  Try to re-login ... probably expired ??
        });
    },
  },
  created() {
    console.info("scroll: created");
    window.addEventListener("scroll", this.onScroll);

    window.eventBus.on("auth/setAuth0Object", (e) => {
      store.dispatch("auth/setAuth0Object", e);
    });
    window.eventBus.on("auth/logout", (e) => {
      store.dispatch("auth/logout", e);
      if (window.moesif) window.moesif.reset();
    });
    window.eventBus.on("user/authfail", (email) => {
      this.$noty.warning("Epostadressen, " + email + ", har ikke tilgang til DeliverIt.");
    });
    window.eventBus.on("user/updateUserInfo", (e) => {
      store.dispatch("user/updateUserInfo", e);
      store.dispatch("account/setAccount", {
        company: e.company,
      });
      if (window.moesif) {
        // Identify the user with Moesif such as when user logs in
        window.moesif.identifyUser(e._id, { email: e.email });
        /* {
          user_id: e._id,
          company_id: e.company.name,
          email: e.email,
        } */
        //TODO: use hashId as company id
        window.moesif.identifyCompany(e.company._id, {
          orgName: e.company.name,
          planName: "basic",
        });
      }
    });
    window.eventBus.on("user/removeUserInfo", (e) => {
      store.dispatch("user/removeUserInfo", e);
    });

    /*
    this.getUserProfile()
      .then(function (response) {
        console.info("FROM getUserProfile()", response);
        store.dispatch("account/setAccount", response.data.user);

        store.dispatch(
          "assignment/setAssignmentList",
          response.data.user.assignments
        );
        //store.dispatch("auth/login", "OK");
      })
      .catch(function (err) {
        console.info("ERROR FROM API:", err);
        //  Try to re-login ... probably expired ??
      });
      */
  },
  mounted() {
    /*this.$getLocation({
      enableHighAccuracy: false, //defaults to false
      timeout: Infinity, //defaults to Infinity
      maximumAge: 0 //defaults to 0
    }).then(coordinates => {
      console.info("COORDS: ", coordinates);
      store.dispatch("settings/setPosition", coordinates);
    });*/
  },
  destroyed() {
    console.info("scroll: destroyed");
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

import { getCapacityList } from "@/services/api/capacity";

export default {
  storeForm(context, payLoad) {
    context.commit("STOREFORM", payLoad);
  },
  resetForm(context) {
    context.commit("RESETFORM");
  },
  setCapacityList(context, payload) {
    context.commit("SET_CAPACITYLIST", payload);
  },
  async fetchCapacityList(context) {
    let capacityList = await getCapacityList();
    context.commit("SET_CAPACITYLIST", capacityList);
  },
};

import Vue from "vue";
import "vue-editable-grid/dist/VueEditableGrid.css";
import "beegridtable/dist/styles/beegridtable.css";
import "./plugins/bootstrap-vue";
import "./plugins/axios";
import "./sass/style.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import * as Sentry from "@sentry/vue";

import BeeGridTable from "beegridtable";
import PubNubVue from "pubnub-vue";
import VueEditableGrid from "vue-editable-grid";

//import { getUserProfile } from "@/services/api/workUsers";
import VueMq from "vue-mq";
import VueNoty from "vuejs-noty";
import "vuejs-noty/dist/vuejs-noty.css";
import "./sass/style.scss";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import i18n from "./i18n";
import Default from "@/layouts/default/standardLayout.vue";
import WorkLayout from "@/layouts/work/workLayout.vue";
//import VueParticles from "vue-particles";

import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";
import mitt from "mitt";
import PortalVue from "portal-vue";

//import VueGeolocation from "vue-browser-geolocation";

import { Auth0Plugin } from "./plugins/auth";

Sentry.init({
  Vue: Vue,
  logErrors: false, //TODO: not in production
  dsn: process.env.VUE_APP_SENTRY_TOKEN,
});

Vue.use(BeeGridTable, {
  capture: true,
  transfer: true,
  zIndex: 2000,
}); //set z-index of beegridtable
Vue.component("vue-editable-grid", VueEditableGrid);

const pubNub_publish_Key = "pub-c-ff8ad612-9fb6-4b1a-9b49-f075aebccc4d";
const pubNub_subscribe_Key = "sub-c-f8d45000-310a-11eb-9d95-7ab25c099cb1";
// Initialize the PubNub client API
Vue.use(
  PubNubVue,
  {
    subscribeKey: pubNub_subscribe_Key,
    publishKey: pubNub_publish_Key,
    ssl: true,
  },
  store
);

Vue.component("standard-layout", Default);
Vue.component("work-layout", WorkLayout);

Vue.use(Vuelidate);
Vue.use(VueNoty, {
  timeout: 4000,
  progressBar: true,
  layout: "bottomRight",
});
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 575,
    sm: 768,
    md: 992,
    lg: 1189,
    xl: Infinity,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS,
    // v: 'OPTIONAL VERSION NUMBER',
    libraries: "places", //,geometry", /// / If you need to use place input
  },
});
//Vue.use(VueParticles);
const emitter = mitt();
window.eventBus = emitter;

//const { VUE_APP_AUTH0_DOMAIN, VUE_APP_AUTH0_CLIENTID } = process.env;
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,

  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.use(PortalVue);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.performance = true;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

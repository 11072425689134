<template>
  <div class="wrapper">
    <keep-alive include="navbar">
      <navbar-work
        :hasSimpleNav="!bignav"
        :navSubTitle="navSubTitle"
        :isFrontPage="true"
      ></navbar-work>
    </keep-alive>

    <slot></slot>

    <keep-alive include="sidebar-right">
      <sidebar-right></sidebar-right>
    </keep-alive>
    <footer-area :expanded="expandfooter"></footer-area>
    <div v-if="showOverlay" class="overlay"></div>
  </div>
</template>

<script>
// import Vue from "vue";
import Navbar from "@/components/navbar/navbarwork.vue";
import Footer from "@/components/footer/footerArea.vue";
//import Sidebar from "@/components/sidebarright/sidebarright.vue";

export default {
  name: "standard-layout",
  data() {
    return {
      showOverlay: false,
    };
  },
  props: {
    bignav: {
      type: Boolean,
      default: false,
    },
    expandfooter: {
      type: Boolean,
      default: true,
    },
    navSubTitle: {
      type: String,
      default: "",
    },
  },
  components: {
    "footer-area": Footer,
    "navbar-work": Navbar,
    "sidebar-right": () =>
      import(
        /* webpackChunkName: "sidebar" */ "@/components/sidebarright/sidebarright.vue"
      ),
  },
};
</script>

export default {
  login(context, token) {
    context.commit("LOGIN", token);
  },
  logout(context, token) {
    context.commit("LOGOUT", token);
  },
  setAuth0Object(context, payload) {
    context.commit("SETAUTH0OBJECT", payload);
  },
};

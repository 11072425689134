const clonedeep = require("lodash.clonedeep");

export default {
  STOREFORM(state, payload) {
    //console.info("committing Form ?", state);
    //state.filterForm = payload;
    state.filterForm = clonedeep(payload);
    //console.info("committing Form ?", state);
  },
  RESETFORM(state) {
    state.filterForm = {};
  },
  SET_FILTERLIST(state, payload) {
    state.filterList = clonedeep(payload);
  },
  SET_CURRENTFILTER(state, payload) {
    state.currentFilter = payload;
  },
  SET_CURRENTFILTER_OWNLIST(state, payload) {
    state.currentFilterOwnlist = payload;
  },
};

import Vue from "vue";

export const getCapacityList = () => {
  return Vue.axios.get("/api/capacity", {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCapacityListWithFilter = (filter) => {
  return Vue.axios.post("/api/capacity/filtered", JSON.stringify(filter), {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCapacity = (id) => {
  return Vue.axios.get("/api/capacity/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

export const saveCapacity = (data) => {
  return Vue.axios.post("/api/capacity", JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteCapacity = (id) => {
  return Vue.axios.delete("/api/capacity/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

export const saveImportedCapacity = (data) => {
  return Vue.axios.post("/api/capacity/imported", JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
};

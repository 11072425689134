export default {
  id: null,
  firstName: null,
  lastName: null,
  fullName: null,
  email: null,
  timeZone: null,
  currentTimeZoneOffset: null,
  language: null,
  isDaylightSavingTime: true,
  activeOrganization: {},
  usersOrganizations: [],
  canTransport: false,
  companyId: "",
  companyName: null,
  companyLogo: null,

  exclusiveCompanyId: null,
  exclusiveCompanySince: null
};

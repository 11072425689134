// Account
export const GET_ACCOUNT = "GET_ACCOUNT";
export const SET_LANGUAGE = "SET_LANGUAGE";

// Auth
export const CHECK_AUTHENTICATION = "CHECK_AUTHENTICATION";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";

// User
export const USERS = "USERS";
export const CURRENT_USER = "CURRENT_USER";

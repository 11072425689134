export default {
  lat: null,
  lng: null,
  cookieAllow: [],
  emailAllow: [],
  phone: [],
  timeZone: null,
  currentTimeZoneOffset: null,
  language: null,
  country: true,
  culture: true,
  area: true
};

//import { getCapacityListWithFilter } from "@/services/api/capacity";

export default {
  /*async fetchFilteredCapacityList(context, payload) {
    let capacityList = await getCapacityListWithFilter(payload);
    if (capacityList && capacityList.data) {
      context.commit("SET_CAPACITYLIST", capacityList.data);
    } else {
      context.commit("SET_CAPACITYLIST", []);
    }
  },*/
  storeForm(context, payLoad) {
    context.commit("STOREFORM", payLoad);
  },
  resetForm(context) {
    context.commit("RESETFORM");
  },
  commitFilter(context, payload) {
    context.commit("SET_CURRENTFILTER", payload);
  },
};

export default {
  isSent(state /*, getters */) {
    return (
      state.capacityForm &&
      state.capacityForm !== "" &&
      typeof state.capacityForm.id !== "undefined"
    );
  },
  capacityForm(state /*, getters */) {
    return state.capacityForm;
  },
  capacityList(state /*, getters */) {
    return state.capacityList;
  },
  favouriteList(state /*, getters */) {
    return state.favouriteList;
  },
};

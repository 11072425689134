//import { USERS, CURRENT_USER } from "./../../mutation-types";

export default {
  updateUserInfo(state, user) {
    state.id = user._id ?? user.id;
    state.firstName = user.firstName;
    state.lastName = user.lastName;
    state.companyName = user.company.name;
  },
  removeUserInfo(state, remove) {
    if (remove === true) {
      state.id = ""; // TODO...
      state.firstName = "";
      state.lastName = "";
      state.companyName = "";
    }
  }
};

"use strict";

import Vue from "vue";
//import VueAxios from "vue-axios";
import axios from "axios";
import { EventBus } from "@/utils/event-bus.js";
import store from "@/store";

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL =
  process.env.VUE_APP_BASE_API_URL || "http://localhost:3001";
axios.defaults.headers.common.Accept = "application/json";
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_BASE_API_URL || "http://localhost:3001", //process.env.baseURL || process.env.BASE_API_URL || "",
  timeout: 60 * 1000, // Timeout
  withCredentials: true, //true // Check cross-site Access-Control
};

const _axios = axios.create(config);
//_axios.defaults.headers.common["Authorization"] = "AUTH_TOKEN tester";

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //console.info("AUTH ?", Vue.$auth);
    // if (!config.headers.Authorization || config.headers.Authorization === "") {

    //if (!config.toOAUTHprovider || config.toOAUTHprovider !== "1") {
    config.withCredentials = true;
    //var cookieName = "deliverit-jwt";
    // eslint-disable-next-line
      //const token = $cookies.get(cookieName); // storage.setItem(cookieName);
    //if (token) config.headers.Authorization = `Bearer ${token}`;

    let dittoken = localStorage.getItem("access_token");
    if (dittoken && dittoken !== "") {
      config.headers.Authorization = `Bearer ${dittoken}`;
      config.headers["x-auth-token"] = dittoken;
      config.headers["X-Organization-Id"] = store
        ? store.getters["account/companyId"]
        : "";
    }
    //}
    config.withCredentials = true;
    return config;
  },
  function (error) {
    // Do something with request error
    console.info("AXIOS request error", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    //return Promise.reject(error);
    if (error.request === undefined && error.response === undefined) {
      var noConnectionError = {
        error: "No Internet connection",
        error_description: "No Internet connection",
        error_code: "NOT_CONNECTED",
      };
      return Promise.reject(noConnectionError);
    }

    if (!error.response) {
      var CORSError = {
        error: "CORS error from this site",
        error_description: "This site has not access til the DeliverIt API",
        error_code: "CORS_ERROR",
      };
      console.log("This site has not access til the DeliverIt API");
      return Promise.reject(CORSError);
    } else {
      if (error.response.status === 401) {
        var AUTH_401_ERROR = {
          error: "Authentication invalid or expired",
          error_description:
            "Authentication to DeliverIt has either expired or never succeeded.",
          error_code: "AUTH_401_ERROR",
        };
        console.log("need to refresh token it expired");

        EventBus.$emit("SHOW_NOTY", { type: "warning", i18nCode: "API_401" });
        store.dispatch("account/logout", {});

        return Promise.reject(AUTH_401_ERROR);
      } else {
        console.log("API response with error");
        /* trackException(
        error,
        store
          ? store.state.account
          : { id: "", firstName: "", lastName: "", activeOrganization: "" }
      ); */
        return Promise.reject(error.response);
      }
    }
  }
);

// eslint-disable-next-line
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
    $http: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);
//Vue.use(VueAxios, Plugin); //axios);

export default Plugin;

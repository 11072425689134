import { GET_ACCOUNT, SET_LANGUAGE } from "./../../mutation-types";

export default {
  setAccount(state, account) {
    //state.id = account.id;
    console.info("IN STORE:", account);
    state.id = account.id;
    state.firstName = account.firstName || account.username;
    state.lastName = account.lastName;
    state.email = account.email;
    state.fullName = account.fullName;
    state.activeOrganization = "";
    //state.firstName = account.firstName || account.username;
    //state.lastName = account.lastName;
    state.activeOrganization = account.company ? account.company : "";
    state.companyName =
      account.company && account.company.name ? account.company.name : "";
    state.companyLogo =
      account.company && account.company.logo ? account.company.logo : "";
    state.companyId = account.company.id;
  },
  setExclusiveCompanyId(state, id) {
    if (state.exclusiveCompanyId !== id) {
      if (state.exclusiveCompanyId != null) {
        state.exclusiveCompanySince = new Date();
      } else {
        state.exclusiveCompanySince = null;
      }
    }
    state.exclusiveCompanyId = id;
  },
  logout(state, account) {
    console.info("USER is logging out: ", account);
    //state.id = account.id;
    state.firstName = ""; // account.firstName;
    //state.lastName = account.lastName;
    //state.activeOrganization = account.company;
  },
  [GET_ACCOUNT](state, account) {
    state.id = account.id;
    state.firstName = account.firstName;
    state.lastName = account.lastName;
    state.timeZone = account.timeZone;
    state.currentTimeZoneOffset = account.currentTimeZoneOffset;
    state.language = account.language;
    state.isDaylightSavingTime = account.isDaylightSavingTime;
    state.activeOrganization = account.activeOrganization;
    state.usersOrganizations = account.usersOrganizations;
  },
  [SET_LANGUAGE](state, account) {
    state.language = account.language;
  }
};
